<template>
  <div>
    <div class='main-page-content'>
      <el-form v-model='searchStatus' :inline='true' label-position='right'>
        <el-form-item>
          <el-input v-model='searchStatus.name' class='input-with-select' clearable
                    placeholder='请输出物流公司名称' @clear='handleQuery'></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model='searchStatus.code' class='input-with-select' clearable
                    placeholder='请输出物流公司编号' @clear='handleQuery'></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' icon='el-icon-search' @click='handleQuery'>查询</el-button>
          <el-button v-if="userPermissions.indexOf('logistic_create') !== -1" type='primary' @click='handleAdd'>
            新增数据
          </el-button>
          <el-upload :before-upload="beforeUpload"
                     :file-list="file"
                     :headers="uploadHeaders"
                     :on-change="handleChange"
                     :on-success="importSuccess"
                     :show-file-list="false"
                     action="/admin_api/logistic_info/import"
                     class="upload-demo"
                     multiple
                     name="order"
                     style="display: inline-block;margin-left: 10px">
            <el-button icon="el-icon-upload" type="primary">批量导入</el-button>
          </el-upload>
        </el-form-item>
      </el-form>

      <div class='default-table'>
        <el-table
            ref='multipleTable'
            :border='true'
            :data='tableData'
            :header-cell-style='headClass'
            row-key='id'
            tooltip-effect='dark'>
          <el-table-column
              align='center'
              label='序号'
              type="index"
              width='50'>
          </el-table-column>
          <el-table-column
              width="180"
              align='center'
              label='物流公司'
              prop='name'>
          </el-table-column>
          <el-table-column
              width="180"
              align='center'
              label='编号'
              prop='code'>
          </el-table-column>
          <el-table-column
              align='center'
              label='类型'
              prop="type"
              width='120'>
          </el-table-column>
          <el-table-column
              align='center'
              label='排序'
              prop='order'
              width='80'>
          </el-table-column>
          <el-table-column
              align='center'
              show-overflow-tooltip
              label='创建时间/修改时间'
              width='260'>
            <template slot-scope='{row}'>
              {{ row.created_at }} / {{ row.updated_at }}
            </template>
          </el-table-column>
          <el-table-column
              align='center'
              label='操作'
              width='120'>
            <template slot-scope='scope'>
              <el-button v-if="userPermissions.indexOf('logistic_edit') !== -1"
                         size='small' style='margin-left: 6px'
                         type='text' @click='handleEdit(scope.row)'>
                修改
              </el-button>
              <el-button v-if="userPermissions.indexOf('logistic_delete') !== -1"
                         size='small'
                         style='margin-left: 6px' type='text' @click='handleDel(scope.row)'>
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row justify='right' type='flex'>
          <el-col :span='24'>
            <Pagination
                :limit.sync='tablePagination.page_size'
                :page.sync='tablePagination.current_page'
                :total='tablePagination.total'
                @pagination='getList'/>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog :title="`物流公司-${isEdit?'修改':'新增'}项目`" :visible.sync='dialogStore'
               center width='550px'>
      <el-form ref='formStore' :model='formStore' :rules='rules' label-poionsit='right' label-width='110px'>
        <el-form-item label='公司名称' prop='name'>
          <el-input v-model='formStore.name' placeholder='请输入公司名称'/>
        </el-form-item>
        <el-form-item label='编号' prop='code'>
          <el-input v-model='formStore.code' placeholder='请输入编号'/>
        </el-form-item>
        <el-form-item label='类型' prop='type'>
          <el-input v-model='formStore.type' placeholder='请输入公司类型'/>
        </el-form-item>
        <el-form-item label='排序' prop='order'>
          <el-input v-model='formStore.order' placeholder='请输入公司排序值'/>
        </el-form-item>
      </el-form>
      <span slot='footer' class='dialog-footer'>
        <el-button @click='dialogStore = false'>取 消</el-button>
        <el-button type='primary' @click='handelStore'>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { mapGetters } from 'vuex'
import { getToken } from '@/utils/auth'

const rules = {
  name: [
    { required: true, message: '请输入公司名称', trigger: 'blur' },
    { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
  ],
  code: [{ required: true, message: '请输入编号', trigger: 'blur' }]

}
export default {
  name: 'PublicationIndex',
  components: { PageHeaderLayout },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  data() {
    return {
      searchStatus: {
        code: '',
        name: ''
      },
      // 数组data
      tableData: [],
      //数组页码
      tablePagination: {
        total: 0,
        page_size: 15,
        current_page: 1
      },
      loadingUpload: false,
      dialogStore: false,
      formStore: {},
      rules,
      isEdit: false,
      uploadHeaders: { 'HYPERF-SESSION-ID': null },
      file: []


    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getList()
    },

    handleAdd() {
      this.formStore = {}
      this.isEdit = false
      this.dialogStore = true
    },
    handleEdit(row) {
      this.formStore = row
      this.isEdit = true
      this.dialogStore = true
    },
    handleQuery() {
      this.tablePagination.current_page = 1
      this.getList()
    },
    handleDel(row) {
      if (row.id) {
        this.$confirm(`确认删除公司【${row.name || '-'}】?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let data = await this.$api.deleteLogisticsCompany({ id: row.id })
          if (data) {
            await this.init()
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      } else
        this.$notify.error('删除对象ID为空')

    },
    async handelStore() {
      this.$refs.formStore.validate(async (valid) => {
        if (valid) {
          let id = await this.$api.saveLogisticsCompany(this.formStore)
          if (id) {
            this.$message.success(`${this.isEdit ? '修改成功' : '新增成功'}`)
            this.dialogStore = false
            this.init()
          }
        }
      })

    },
    searchCondition() {
      let searchCondition = {
        page_size: this.tablePagination.page_size,
        current_page: this.tablePagination.current_page
      }
      this.searchStatus.code !== '' ? searchCondition['code'] = this.searchStatus.code : ''
      this.searchStatus.name !== '' ? searchCondition['name'] = this.searchStatus.name : ''
      return searchCondition
    },
    async getList() {
      let searchCondition = this.searchCondition()
      let { list, pages } = await this.$api.getLogisticsList(searchCondition)
      this.$nextTick(() => {
        this.tableData = list
        this.tablePagination = pages
        this.tablePagination.current_page = pages.current_page || 1
        this.tablePagination.page_size = pages.page_size || 1
      })

    },
    //批量样式居中
    headClass() {
      return 'text-align:center'
    },

    //下载上传模块
    handleChange(file) {
      this.file = [file]
    },
    importSuccess(response) {
      if (response.code === 200) {
        this.$notify.success('上传成功')
        this.submitted = []
        // this.dataList = response.data.list || []
        this.getList()
      } else {
        this.$notify.warning(response.msg)
      }
    },
    beforeUpload() {
      this.uploadLoading = true
      this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()
    }

  }

}
</script>
<style lang='scss'>
#foo > .el-checkbox__input.is-checked .el-checkbox__inner, .foo > * > .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #409EFF;
  border: 1px solid #409EFF;

}

#foo > * > .el-checkbox__inner:hover, .foo > * > * > .el-checkbox__inner:hover {
  border-color: #409EFF !important;
}

.foo > * > span:last-child {
  display: none;
}

.download {
  display: flex;
  float: right;
  margin-left: 10px;
}
</style>
